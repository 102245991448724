// Dependencies
import React from "react";
import { useFormik } from "formik";

// Components
import Input from "../Input";
import Button from "../Button";

// Schemas
import emailSquema from "../../schemas/emailSchema";

function EmailForm({ submit, isSubmitting = false }) {
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: emailSquema,
    onSubmit: (values, { resetForm }) => {
      submit(values, resetForm);
    },
    validateOnMount: true,
  });

  return (
    <form
      className="grow flex flex-col justify-between"
      onSubmit={formik.handleSubmit}
    >
      <Input
        label="Email"
        type="text"
        id="email"
        value={formik.values.email}
        touched={formik.touched.email}
        error={formik.errors.email}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
      />
      <div className="flex justify-center">
        <Button
          type="submit"
          title="Confirmar"
          isLoading={isSubmitting}
          disabled={!formik.isValid}
        />
      </div>
    </form>
  );
}

export default EmailForm;
