// Dependencies
import React from "react";
import { useFormik } from "formik";

// Components
import Input from "../Input";
import Button from "../Button";

// Schemas
import codeSquema from "../../schemas/codeSchema";

function CodeForm({ submit }) {
  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: codeSquema,
    onSubmit: (values, { resetForm }) => {
      submit(values, resetForm);
    },
    validateOnMount: true,
  });

  return (
    <form
      className="grow flex flex-col justify-between"
      onSubmit={formik.handleSubmit}
    >
      <Input
        label="Código"
        type="password"
        id="code"
        value={formik.values.code}
        touched={formik.touched.code}
        error={formik.errors.code}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
      />
      <div className="flex justify-center">
        <Button type="submit" title="Continuar" disabled={!formik.isValid} />
      </div>
    </form>
  );
}

export default CodeForm;
