// Dependencies
import React from "react";

function Input({
  label = "",
  type = "text",
  id = "",
  value = null,
  touched = null,
  error = null,
  handleChange = () => {},
  handleBlur = () => {},
}) {
  return (
    <div className="flex flex-col mb-8">
      <label for={id} className="mb-2">
        {label}
      </label>
      <input
        type={type}
        name={id}
        id={id}
        value={value}
        className={touched && error ? "input-incorrect" : "input-custom"}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {touched && error && (
        <div className="pt-2 text-xs text-red-500">{error}</div>
      )}
    </div>
  );
}

export default Input;
