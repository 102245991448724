// Dependencies
import React, { useState } from "react";

function CopyButton({ textToCopy = "" }) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setIsCopied(true);
      })
      .catch(() => {
        alert("No se pudo copiar el enlace");
      });
  };

  return (
    <div className="flex justify-center">
      {isCopied ? (
        <div className="flex items-center text-primary px-5 py-1 rounded-md">
          <img className="h-4" src="./assets/check.svg" alt="check-icon" />
          <p className="text-base ml-2">Copiado</p>
        </div>
      ) : (
        <div
          className="flex items-center border bg-primary text-white px-5 py-2 rounded-3xl cursor-pointer"
          onClick={handleCopy}
        >
          <img className="h-4" src="./assets/copy.svg" alt="copy-icon" />
          <p className="text-base ml-2">Copiar</p>
        </div>
      )}
    </div>
  );
}

export default CopyButton;
