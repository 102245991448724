// Dependencies
import React from "react";

// Services
import { generateTinkLink } from "../../services/tink";

const errorMessages = {
  BAD_REQUEST: {
    description: "Ha ocurrido un error en la generación de la solicitud.",
    user: "Por favor, vuelva a intentarlo o contacte con un agente.",
  },
  USER_CANCELLED: {
    description: "El usuario ha cancelado la solicitud.",
    user: "Por favor, vuelva a iniciar y termine la solicitud.",
  },
  AUTHENTICATION_ERROR: {
    description:
      "El usuario no ha podido autenticarse correctamente con su banco.",
    user: "Por favor, vuelva a intentarlo.",
  },
  INTERNAL_ERROR: {
    description: "Un error interno ha ocurrido.",
    user: "Por favor, vuelva a intentarlo o contacte con un agente.",
  },
  TEMPORARY_ERROR: {
    description: "Un error inesperado ha ocurrido.",
    user: "Por favor, vuelva a intentarlo o contacte con un agente.",
  },
};

function FailResult({ error = "INTERNAL_ERROR", id = "" }) {
  return (
    <div className="flex flex-col shadow-bg rounded-lg bg-white max-w-960 max-h-900 p-8">
      <h1 className="text-primary text-2xl font-bold mb-6">
        Proceso terminado sin éxito
      </h1>
      <div>
        <p className="text-red-500 mb-1">{errorMessages[error].description}</p>
        <p className="mb-8">{errorMessages[error].user}</p>
      </div>
      <a
        className="w-fit"
        href={generateTinkLink(id, "advancing")}
        target="_self"
        rel="noreferrer"
      >
        <div className="text-white font-bold bg-primary rounded px-3 py-1 shadow-bg content-fit">
          Reintentar
        </div>
      </a>
    </div>
  );
}

export default FailResult;
