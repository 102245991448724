// Dependencies
import React, { useState } from "react";

// Components
import CodeForm from "../../components/CodeForm";
import EmailForm from "../../components/EmailForm";
import CopyButton from "../../components/CopyButton";

// API functions
import { getContact } from "../../services/api";

// Services
import { generateTinkLink } from "../../services/tink";

function GenerateLink() {
  const [isCodeOk, setIsCodeOk] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tinkLink, setTinkLink] = useState(null);

  const emailSubmit = (values, resetForm) => {
    setIsSubmitting(true);
    getContact(values.email, "advancing")
      .then(({ data }) => {
        setIsSubmitting(false);
        resetForm();
        if (data.total === 0) {
          alert("No existe un contacto con dicho email");
        } else {
          const link = generateTinkLink(data.results[0].id, "advancing");
          setTinkLink(link);
        }
      })
      .catch((error) => {
        setIsSubmitting(false);
        resetForm();
        alert(error);
      });
  };

  const codeSubmit = (values, resetForm) => {
    setIsCodeOk(true);
    resetForm();
  };

  const formRender = () => {
    if (isCodeOk) {
      if (tinkLink) {
        return (
          <>
            {/* <h1 className="text-xl font-bold pb-6">Código correcto</h1> */}
            <h1 className="text-xl font-bold pb-8">
              Tink link generado correctamente
            </h1>
            <a
              className="mb-8"
              href={tinkLink}
              target="_blank"
              rel="noreferrer"
            >
              <div className="border border-primary rounded p-3 shadow-bg transition hover:bg-slate-50">
                <p className="text-sm text-primary text-clip word-wrap">
                  {tinkLink}
                </p>
              </div>
            </a>
            <CopyButton textToCopy={tinkLink} />
          </>
        );
      } else {
        return (
          <>
            <h1 className="text-xl font-bold pb-6">
              Introduce el email del usuario
            </h1>
            {/* <h1 className="pb-6">Código correcto</h1> */}
            <EmailForm submit={emailSubmit} isSubmitting={isSubmitting} />
          </>
        );
      }
    } else {
      return (
        <>
          <h1 className="text-xl font-bold pb-6">
            Inserta el código de seguridad
          </h1>
          <CodeForm submit={codeSubmit} />
        </>
      );
    }
  };

  return (
    <div className="flex justify-center items-center bg-grey-ctm p-8 h-screen">
      <div className="flex flex-col items-center shadow-bg rounded-lg bg-white max-w-960 max-h-900 w-full h-full">
        <div className="flex flex-col text-base pt-20 pb-16 px-6 max-w-400 w-full h-full overflow-y-scroll">
          <div className="flex justify-center pb-8">
            <h1 className="text-primary text-2xl font-bold">
              Generador de Tink Link
            </h1>
          </div>
          <div className="flex flex-col grow">{formRender()}</div>
        </div>
      </div>
    </div>
  );
}

export default GenerateLink;
