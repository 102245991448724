import axios from "axios";

const {
  REACT_APP_VENDEPT_API_URL,
  REACT_APP_CLIENT_TOKEN_ADVANCING,
  REACT_APP_CLIENT_TOKEN_MENAI,
} = process.env;

const businessToken = {
  advancing: REACT_APP_CLIENT_TOKEN_ADVANCING,
  menai: REACT_APP_CLIENT_TOKEN_MENAI,
};

const apiInstance = axios.create({
  baseURL: REACT_APP_VENDEPT_API_URL,
});

export function getContact(email, business) {
  return apiInstance.post(
    "/api/mitek/get-contact",
    { email: email },
    {
      headers: {
        Authorization: `Bearer ${businessToken[business]}`,
      },
    }
  );
}

export function retrieveResult(retrieveId, contactId, business) {
  const data = { retrieveId: retrieveId, hubspotId: contactId };

  return apiInstance.post("/api/tink/retrieve-result", data, {
    headers: { Authorization: `Bearer ${businessToken[business]}` },
  });
}

export function saveFailResult(data, contactId, business) {
  const dataSend = { data: data, hubspotId: contactId };

  return apiInstance.post("/api/tink/save-fail-result", dataSend, {
    headers: { Authorization: `Bearer ${businessToken[business]}` },
  });
}
