// Dependencies
import React from "react";

function Thanks() {
  return (
    <div className="flex flex-col shadow-bg rounded-lg bg-white max-w-960 max-h-900 p-8">
      <h1 className="text-primary text-2xl font-bold mb-6">¡Muchas gracias!</h1>
      <div>
        <p className="mb-1">El proceso ha sido terminado con éxito</p>
        <p className="mb-2">Puede salir de la plataforma</p>
      </div>
    </div>
  );
}

export default Thanks;
