// Dependencies
import React from "react";

function Button({
  type = "button",
  title = "...",
  disabled = false,
  isLoading = false,
}) {
  return (
    <button
      className={
        disabled
          ? "opacity-30 bg-primary text-white font-bold py-3 px-10 w-fit rounded-3xl"
          : "shadow-bg bg-primary text-white font-bold py-3 px-10 w-fit rounded-3xl"
      }
      type={type}
      disabled={disabled}
    >
      {isLoading ? (
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        title
      )}
    </button>
  );
}

export default Button;
