const {
  REACT_APP_ENV,
  REACT_APP_TINK_BASE_URL,
  REACT_APP_CLIENT_ID_ADVANCING,
  REACT_APP_CLIENT_ID_MENAI,
} = process.env;

const businessKey = {
  advancing: REACT_APP_CLIENT_ID_ADVANCING,
  menai: REACT_APP_CLIENT_ID_MENAI,
};

export function generateTinkLink(contactId, business) {
  const queryParams = new URLSearchParams({
    client_id: businessKey[business],
    redirect_uri: `${window.location.origin}/callback`,
    market: "ES",
    locale: "es_ES",
    state: contactId,
  });

  if (REACT_APP_ENV === "test") {
    queryParams.append("test", true);
  }

  return `${REACT_APP_TINK_BASE_URL}?${queryParams.toString()}`;
}
