// Dependencies
import { Navigate, Route, Routes } from "react-router-dom";

// Pages
import GenerateLink from "./pages/GenerateLink";
import Callback from "./pages/Callback";
import Thanks from "./pages/Thanks";
import NotFound from "./pages/NotFound";

// General css
import "./App.css";
import "./button-spinner.css";
import "./primary-color-spinner.css";

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Navigate to="/generate-link" />} />
      <Route path="/generate-link" element={<GenerateLink />} />
      <Route path="/callback" element={<Callback />} />
      <Route path="/thanks" element={<Thanks />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
