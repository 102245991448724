// Dependencies
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

// Components
import FailResult from "../../components/FailResult";
import Thanks from "../../components/Thanks";

// Services
import { retrieveResult, saveFailResult } from "../../services/api";

function Callback() {
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const [objectParams, setObjectParams] = useState(null);

  const paramsToObject = (entries) => {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  };

  useEffect(() => {
    let params = paramsToObject(searchParams.entries());
    setObjectParams(params);
    if (params.error) {
      saveFailResult(params, params.state, "advancing").then(() => {
        setIsLoading(false);
      });
    } else {
      retrieveResult(
        params.account_verification_report_id,
        params.state,
        "advancing"
      )
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          params = { ...params, error: "INTERNAL_ERROR" };
          setObjectParams(params);
          setIsLoading(false);
        });
    }
    setObjectParams(params);
    // eslint-disable-next-line
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center bg-grey-ctm p-8 h-screen">
        <div className="lds-ring-primary">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center bg-grey-ctm p-8 h-screen">
      {objectParams.error ? (
        <FailResult error={objectParams.error} id={objectParams.state} />
      ) : (
        <Thanks />
      )}
    </div>
  );
}

export default Callback;
